import { extendTheme } from '@chakra-ui/react'
import { createIcon } from '@chakra-ui/icons'


const theme = extendTheme({
  // colors: {},  
})

export default theme

export const DisplayIcon = createIcon({
  displayName: 'DisplayIcon',
  viewBox: '0 0 640 512',
  path: (
    <g>
      <path d="M256 416h-32V96h96v32c0 17.69 14.33 32 32 32s32-14.31 32-32V64c0-17.69-14.33-32-32-32H32C14.33 32 0 46.31 0 64v64c0 17.69 14.33 32 32 32s32-14.31 32-32V96h96v320h-32c-17.67 0-32 14.31-32 32s14.33 32 32 32h128c17.67 0 32-14.31 32-32s-14.3-32-32-32zm352-192H352c-17.67 0-32 14.31-32 31.1v32c0 17.69 14.33 32 32 32s32-14.31 32-32h64v128h-16c-17.67 0-32 14.31-32 32s14.33 32 32 32h96c17.67 0 32-14.31 32-32s-14.33-32-32-32h-16v-128h64c0 17.69 14.33 32 32 32s32-14.31 32-32v-32c0-16.8-14.3-31.1-32-31.1z"/>
    </g>
  ),
})

export const LayoutIcon = createIcon({
  displayName: 'LayoutIcon',
  viewBox: '0 0 512 512',
  path: (
    <g>
      <path d="M0 96c0-35.35 28.65-64 64-64h384c35.3 0 64 28.65 64 64v320c0 35.3-28.7 64-64 64H64c-35.35 0-64-28.7-64-64V96zm224 320h224V96H224v320zM88 96c-13.25 0-24 10.7-24 24s10.75 24 24 24h48c13.3 0 24-10.7 24-24s-10.7-24-24-24H88zm0 144h48c13.3 0 24-10.7 24-24s-10.7-24-24-24H88c-13.25 0-24 10.7-24 24s10.75 24 24 24zm0 48c-13.25 0-24 10.7-24 24s10.75 24 24 24h48c13.3 0 24-10.7 24-24s-10.7-24-24-24H88z"/>
    </g>
  ),
})

export const PaintIcon = createIcon({
  displayName: 'PaintIcon',
  viewBox: '0 0 576 512',
  path: (
    <g>
      <path d="M224 263.3c.2-30 14.4-58.1 38.4-76.1L499.1 9.605c18.6-13.958 44.5-12.57 61.6 3.295 17 15.86 20.1 41.64 7.5 61.17L406.5 324.1c-15.2 23.6-39.9 39.1-67.2 43L224 263.3zM320 400c0 61.9-50.1 112-112 112H64c-17.67 0-32-14.3-32-32s14.33-32 32-32h4.81c17.63 0 29.59-18.9 27.78-36.4-.39-3.8-.59-7.7-.59-11.6 0-60.4 47.9-109.7 107.7-111.9l116.1 104.4c.1 1.6.2 5 .2 7.5z"/>
    </g>
  ),
})

export const UploadIcon = createIcon({
  displayName: 'UploadIcon',
  viewBox: '0 0 512 512',
  path: (
    <g>
      <path d="M105.4 182.6c12.5 12.49 32.76 12.5 45.25.001L224 109.3V352c0 17.67 14.33 32 32 32s32-14.33 32-32V109.3l73.38 73.38c12.49 12.49 32.75 12.49 45.25-.001 12.49-12.49 12.49-32.75 0-45.25l-128-128C272.4 3.125 264.2 0 256 0s-16.4 3.125-22.6 9.375L105.4 137.4c-12.52 12.5-12.52 32.7 0 45.2zM480 352H320c0 35.35-28.65 64-64 64s-64-28.65-64-64H32c-17.67 0-32 14.33-32 32v96c0 17.67 14.33 32 32 32h448c17.67 0 32-14.33 32-32v-96c0-17.7-14.3-32-32-32zm-48 104c-13.2 0-24-10.8-24-24s10.8-24 24-24 24 10.8 24 24-10.8 24-24 24z"/>
    </g>
  ),
})

export const OutlookIcon = createIcon({
  displayName: 'OutlookIcon',
  viewBox: '0 0 32 32',
  path: (
    [<g fill="currentColor"><path fill="currentColor" d="M19.484 7.937v5.477l1.916 1.205a.489.489 0 0 0 .21 0l8.238-5.554a1.174 1.174 0 0 0-.959-1.128Z" /><path fill="currentColor" d="m19.484 15.457 1.747 1.2a.522.522 0 0 0 .543 0c-.3.181 8.073-5.378 8.073-5.378v10.066a1.408 1.408 0 0 1-1.49 1.555h-8.874v-7.443ZM10.44 12.932a1.609 1.609 0 0 0-1.42.838 4.131 4.131 0 0 0-.526 2.218A4.05 4.05 0 0 0 9.02 18.2a1.6 1.6 0 0 0 2.771.022 4.014 4.014 0 0 0 .515-2.2 4.369 4.369 0 0 0-.5-2.281 1.536 1.536 0 0 0-1.366-.809Z" /><path fill="currentColor" d="M2.153 5.155v21.427L18.453 30V2Zm10.908 14.336a3.231 3.231 0 0 1-2.7 1.361 3.19 3.19 0 0 1-2.64-1.318A5.459 5.459 0 0 1 6.706 16.1a5.868 5.868 0 0 1 1.036-3.616 3.267 3.267 0 0 1 2.744-1.384 3.116 3.116 0 0 1 2.61 1.321 5.639 5.639 0 0 1 1 3.484 5.763 5.763 0 0 1-1.035 3.586Z"/></g>]
  ),
})

export const AppleIcon = createIcon({
  displayName: 'AppleIcon',
  viewBox: '0 0 32 32',
  path: (
    [<g fill="currentColor"><path fill="currentColor" d="M10.7 31c-2.6 0-4.4-2.3-5.7-4.3-3.3-5.1-4-11.5-1.6-15.2 1.6-2.5 4.2-4 6.7-4 1.3 0 2.4.4 3.3.7.7.3 1.4.5 2.1.5.6 0 1.1-.2 1.8-.5.9-.3 2-.7 3.5-.7 2.2 0 4.5 1.2 6.1 3.2.2.2.3.5.2.8-.1.3-.2.5-.5.7-1.8 1-2.8 2.8-2.6 4.8.1 2.1 1.4 3.8 3.3 4.5.3.1.5.3.6.6.1.3.1.5 0 .8-.7 1.5-1 2.2-1.9 3.5-1.5 2.2-3.3 4.5-5.7 4.5-1.1 0-1.8-.3-2.4-.6-.6-.3-1.2-.6-2.4-.6-1.1 0-1.7.3-2.4.6-.6.4-1.3.7-2.4.7z" /><path fill="currentColor" d="M14.7 7.7h-.2c-.5 0-.9-.4-1-.8-.3-1.7.3-3.7 1.6-5.3 1.2-1.5 3.2-2.5 5-2.7.5 0 1 .3 1.1.9.3 1.8-.3 3.7-1.6 5.3-1.1 1.6-3.1 2.6-4.9 2.6z" /></g>]
  ),
})

export const GoogleIcon = createIcon({
  displayName: 'GoogleIcon',
  viewBox: '0 0 24 24',
  path: (
    <g fill="currentColor">
      <path fill="currentColor" d="M11.99 13.9v-3.72h9.36c.14.63.25 1.22.25 2.05 0 5.71-3.83 9.77-9.6 9.77-5.52 0-10-4.48-10-10S6.48 2 12 2c2.7 0 4.96.99 6.69 2.61l-2.84 2.76c-.72-.68-1.98-1.48-3.85-1.48-3.31 0-6.01 2.75-6.01 6.12s2.7 6.12 6.01 6.12c3.83 0 5.24-2.65 5.5-4.22h-5.51v-.01Z" />
    </g>
  ),
})

export const GitHubIcon = createIcon({
  displayName: 'GitHubIcon',
  viewBox: '0 0 24 24',
  path: (
    <g fill="currentColor">
      <path d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z"/>
    </g>
  ),
})